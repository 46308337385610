<template>
<div>
  
  <div class="row">
    <div class="col-md-3" @click="haddleChangeToOderList('pending')">
      <dashbord-cards style="cursor: pointer;" :type="cards[0].type" :cardBg="cards[0].cardBg" :name="cards[0].name" :value="cards[0].value" :icon="cards[0].icon" />
    </div>
    <div class="col-md-3" @click="haddleChangeToOderList('processing')">
      <dashbord-cards style="cursor: pointer;" :type="cards[1].type" :cardBg="cards[1].cardBg" :name="cards[1].name" :value="cards[1].value" :icon="cards[1].icon"/>    
    </div>
    <div class="col-md-3" @click="haddleChangeToOderList('ready')">
      <dashbord-cards style="cursor: pointer;" :type="cards[2].type" :cardBg="cards[2].cardBg" :name="cards[2].name" :value="cards[2].value" :icon="cards[2].icon"/>    
    </div>
    <div class="col-md-3" @click="haddleChangeToOderList('shipped')">
      <dashbord-cards style="cursor: pointer;" :type="cards[3].type" :cardBg="cards[3].cardBg" :name="cards[3].name" :value="cards[3].value" :icon="cards[3].icon"/>    
    </div>
  </div>
  <div class="row">

    <div class="col-md-8">
      <div class="panel panel-default chartPanel" style="background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;">
        
        <b-card header="Total Sales">
          <div class="chart-wrapper">
            <DynamicChartLine chartId="chart-bar-01" v-bind:update="update" :styles="chart_totalsales.chartstyle" v-bind:chartData="chart_totalsales.chartdata" v-bind:options="chart_totalsales.chartoptions" />
             
          </div>
        </b-card>

      </div>
    </div>

    <div class="col-md-4">
      <div class="panel panel-default chartPanel" style="background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;">
        <b-card header="Best Selling Products Chart">
          <div class="chart-wrapper" >
            <DynamicChartDougnut 
              chartId="Best-Selling-Products-Chart"
              v-bind:update="updatepie"
              v-bind:chartData="chart_bestsales.chartdata"
              v-bind:options="chart_bestsales.chartoptions"
            />
            <div style="width:100%">
            <ul class="dougnutDataList">
              <li v-for="(items, index) in chart_bestsales.chartdata.labels" :key="index">
              <span :id="'pieChColor'+index" class="pieChColorGuide">.</span>
                {{items}}
              </li>
            </ul>
            </div>
          </div>
        </b-card>

     </div>
    </div>

  </div>

  <div class="row" id="lower-row">
    <div class="col-md-3"  @click="haddleChangeToOderList('delivered')">
      <dashbord-cards style="cursor: pointer;" :type="cards[4].type" :cardBg="cards[4].cardBg" :name="cards[4].name" :value="cards[4].value" :icon="cards[4].icon"/>          
    </div>
    <div class="col-md-3" @click="haddleChangeToOderList('deliveredFailed')">
      <dashbord-cards style="cursor: pointer;" :type="cards[5].type" :cardBg="cards[5].cardBg" :name="cards[5].name" :value="cards[5].value" :icon="cards[5].icon"/>          
    </div>
    <div class="col-md-3" @click="haddleChangeToCancelledOderList()">
      <dashbord-cards style="cursor: pointer;" :type="cards[6].type" :cardBg="cards[6].cardBg" :name="cards[6].name" :value="cards[6].value" :icon="cards[6].icon"/>          
    </div>
    <div class="col-md-3" @click="haddleChangeToDisputesOderList()">
      <dashbord-cards style="cursor: pointer;" :type="cards[7].type" :cardBg="cards[7].cardBg" :name="cards[7].name" :value="cards[7].value" :icon="cards[7].icon"/>          
    </div>
  </div>

</div>  
</template>
<script>
  import DynamicChartLine from '@/components/common/Dashbord/DynamicChartLine'
  import DynamicChartDougnut from '@/components/common/Dashbord/DynamicChartDougnut'
  import MonthlySales from '@/components/common/Dashbord/MonthlySales'
  import DashbordCards from '@/components/common/Dashbord/DashbordCards';
  import mixinDashboard from "@/mixins/merchant/APIDashboard";
  export default {
    components: { 
      MonthlySales,
      DashbordCards,
      DynamicChartLine,
      DynamicChartDougnut
    },
    mixins:[mixinDashboard],
    watch: {
      '$store.state.merchant_selectedstore': function(val) {
        this.handleProductReview();
        this.handleBestSales();
        this.handleOrderswithStatus();
      },
    },
    data: function () {
      return {
        showChart: true,
        showChartEntities: true,
        showChartVisitors: true,
        showChartCountConvertion: true,
        showChartReterningCustomer: true,
        showChartAvgOrderVal: true,
        breadcrumb: [
          {
            text: "Dashboard",
            href: "/merchant-dashbord",
            active: true
          }
        ],
        chart_totalsales: {
          chartdata: {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
              {
                label: 'Card Payments',
                backgroundColor: 'rgb(141, 213, 172, 0)',
                borderColor: "rgb(141, 213, 172)",
                data: [],
                fill: true
              },
              {
                label: 'Cash on Delivery',
                backgroundColor: 'rgb(154, 215, 233, 0.3)',
                borderColor: "rgb(154, 215, 233)",
                data: [],
                fill: true
              }
            ]
          },
          chartstyle: {
            height: `auto`,
            width: `auto`
          },
          mdata:{},
          chartoptions: {
            responsive: true,
            maintainAspectRatio: false,
            scaleShowVerticalLines: false,
            tooltips: {
              enabled: true,
              intersect: true,
              position: 'nearest',
              callbacks: {
                labelColor: function (tooltipItem, chart) {
                  return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
                }
              }
            },
            scales: {
              xAxes: [{
                gridLines: {
                  drawOnChartArea: false
                }
              }]
            },
            annotation: {
              annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 5,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 4,
                label: {
                  enabled: false,
                  content: 'Test label'
                }
              }]
            }
          }
        },
        chart_bestsales: {
          chartdata: {
            labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs',],
            datasets: [
              {
                backgroundColor: [
                  '#41B883',
                  '#E46651',
                  '#00D8FF',
                  '#DD1B16'
                ],
                data: [40, 20, 80, 10]
              }
            ]
          },
          chartstyle: {
            height: `auto`,
            width: `auto`
          },
          chartoptions: {
            responsive: true,
            maintainAspectRatio: false, 
            legend: {
                display: false
            },
          }
        },
        cards: [
          {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Pending Orders",
           value: "0",
           icon: "fa fa-shopping-cart",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Processing Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Ready to Pickup Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Shipped Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Delivered Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Delivery Failed Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Cancelled Orders",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         },
         {
           cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
           name: "Disputes & Returns",
           value: "0",
           icon: "fa fa-tachometer",
           type: "card-text"
         }
         // ddd
        ],
        update: 0,
        updatepie: 0
      }
    },
    created(){
      this.handleBreadcrumb();
      this.handleTotalSales();
      this.handleBestSales();
      this.getTotalReterningCustomerChartData(1);
      this.handleOrderswithStatus();
    },
    methods: {
      handleBreadcrumb: function() {
        this.$store.commit("setBreadcrumbSwitchState", {
          action: null,
          payload: this.breadcrumb
        });
      },
      handleTotalSales: async function() {
        try { 
          let data = await this.getChartData_CardCash();
          let cash_array = [];
          if(data.chart_data_cash.length > 0){
            cash_array = [0,0,0,0,0,0,0,0,0,0,0,0];
            for (let index = 0; index < data.chart_data_cash.length; index++) {
              let monthnumber = data.chart_data_cash[index]._id;
              cash_array[monthnumber - 1 ] = data.chart_data_cash[index].total_salse;
            }
            this.$set(this.chart_totalsales.chartdata.datasets[1],'data' , cash_array);
          }
          let card_array = [];
          if(data.chart_data_card.length > 0){
            card_array = [0,0,0,0,0,0,0,0,0,0,0,0];
            for (let index = 0; index < data.chart_data_card.length; index++) {
              let monthnumber = data.chart_data_card[index]._id;
              card_array[monthnumber - 1 ] = data.chart_data_card[index].total_salse;
              this.$set(this.chart_totalsales.chartdata.datasets[0],'data' , card_array)
            }
          }
          this.update = 1;
        } catch (error) {
          throw(error);
        }
      },
      handleProductReview: async function() {
        try { 
          let data = await this.getChartData_PendingOrders(localStorage.merchant_selectedstore);
          this.cards[0].value = data.count + "";
        } catch (error) {
          throw error;
        }
      },
      getProcessingCount: async function() {
        try {
          let params = ""; 
          params += "&order_line_status=" + 10;
          let data = await this.getOderStatusCount(params);
          this.cards[1].value = data.count + "";
        } catch (error) {
          throw error;
        }
      },
      getReadyToPickupCount: async function() {
        try {
          let params = ""; 
          params += "&order_line_status=" + 20;
          let data = await this.getOderStatusCount(params);
          this.cards[2].value = data.count + "";
        } catch (error) {
          throw error;
        }
      },
      getShippedOrdersCount: async function() {
        try {
          let params = ""; 
          params += "&order_line_status=" + 30;
          let data = await this.getOderStatusCount(params);
          this.cards[3].value = data.count + "";
        } catch (error) {
          throw error;
        }
      },
      getDeliveredCount: async function() {
        try {
          let params = ""; 
          params += "&order_line_status=" + 40;
          let data = await this.getOderStatusCount(params);
          this.cards[4].value = data.count.toString();
        } catch (error) {
          throw error;
        }
      },
      getDeliveredFailedCount: async function() {
        try {
          let data = await this.getOderDeliveredFailedCount();
          this.cards[5].value = data.count + "";
        } catch (error) {
          throw error;
        }
      },
      disputeReturnCount: async function() {
        try {
          let params = "";
          let data = await this.getDispatchListCount(params);
          this.cards[7].value = data.result + "";
        } catch (error) {
          throw error;
        }
      },
      getCancelledOrdersCount: async function() {
        try {
          let params = ""; 
          params += "&order_line_status=" + 0;
          let data = await this.getOderStatusCount(params);
          this.cards[6].value = data.count.toString();
        } catch (error) {
          throw error;
        }
      },
      handleOrderswithStatus: function() {
        this.handleProductReview();
        this.getProcessingCount();
        this.getReadyToPickupCount();
        this.getShippedOrdersCount();
        this.getDeliveredCount();
        this.getDeliveredFailedCount();
        this.disputeReturnCount();
        this.getCancelledOrdersCount();
      },
      // dashbord total Reterning Customer count
      getTotalReterningCustomerChartData: async function(id) {
        try {
          this.showChartReterningCustomer = false;
          let response = await this.getTotalReturningCustomerApi(id);
          if (response) {
            // this.cards[5].value = response.count + "";
            response.chart_data.reverse();
            let dataX = [];
            response.chart_data.forEach(element => {
              dataX.push(element.total_count);
              // this.cards[5].chartdata.datasets[0].data = dataX;
            });
          }
          this.showChartReterningCustomer = true;
        } catch (error) {
          throw(error);
          this.showChartReterningCustomer = true;
        }
      },
      handleBestSales: async function() {
        try {
          if (this.$store.state.merchant_selectedstore !== '-1') {           
            // alert(this.$store.state.merchant_selectedstore)
            let data = await this.getChartData_PieBestProducts(this.$store.state.merchant_selectedstore);
            // alert(data)
            let labels = []
            let chartdata = []
            for (let index = 0; index < data.chart_data.length; index++) {
              if(data.chart_data[index].product_name.length > 10){
                data.chart_data[index].product_name = data.chart_data[index].product_name.substring(0,20)+ "...";
              }
              labels.push(data.chart_data[index].product_name);
              chartdata.push(data.chart_data[index].total_count);
            }
            this.$set(this.chart_bestsales.chartdata,'labels' , labels)
            this.$set(this.chart_bestsales.chartdata.datasets[0],'data' , chartdata)
          
          } else {
            let data = await this.getChartData_PieBestProductsWithOutStore();
            let labels = []
            let chartdata = []
            for (let index = 0; index < data.chart_data.length; index++) {
              if(data.chart_data[index].product_name.length > 10){
                data.chart_data[index].product_name = data.chart_data[index].product_name.substring(0,20)+ "...";
              }
              labels.push(data.chart_data[index].product_name);
              chartdata.push(data.chart_data[index].total_count);
            }
            this.$set(this.chart_bestsales.chartdata,'labels' , labels)
            this.$set(this.chart_bestsales.chartdata.datasets[0],'data' , chartdata)

          }
          this.updatepie = 1;
        } catch (error) {
          throw error;
        }
      },
      haddleChangeToOderList: function(status) {
        // console.log(status);
        localStorage.setItem("merchant_dashboard_status", status);
        this.$router.push("/merchant-order-group");
      },
      haddleChangeToCancelledOderList: function() {
        this.$router.push("/merchant-cancelled-order");
      },
      haddleChangeToDisputesOderList: function() {
        this.$router.push("/merchant-dispute-list");
      }
      // new routs
    } 
  }

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
#chart-bar-01 {
  height: 305px !important;
}
#Best-Selling-Products-Chart {
  height: 180px !important;
}

.pieChColorGuide {
  margin-right: 5px !important;
  font-size: 100px;
  height: 20px;
}
.chartPanel {
  padding: 20px !important;
  height: 370px !important;
  border-color: white !important;
}

.dougnutDataList {
  position: absolute;
  top: 55%;
  line-height: 0px !important;
}

.dougnutDataList li {
  height: 22px;
}

#pieChColor0{
  color: #41B883 !important;
}
#pieChColor1 {
  color: #E46651 !important;
}
#pieChColor2 {
  color: #00D8FF !important;
}
#pieChColor3 {
  color: #DD1B16 !important;
}

#pieChColor4 {
  color: #e8e8e8 !important;
}



.checkbox:checked:before{
  background-color:green;
}

</style>